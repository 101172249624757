import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import Utils from '../../services/utils';
import img from "../../images/placeholder.png"

const OrderDetailCard = observer(({card}) => {
    const {app} = useContext(Context);

  return (
    <div className='order-detail-card'>
        <div className='order-detail-flex'>
            <img src={card.image ? card.image : img} alt={card.title} title={card.title}
                width={107}
                height={107}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src = img
                }}
            />
            <div className='order-detail-content'>
                {card.count > 1 ? <h5>{card.count} x {card.title}</h5> : <h5>{card.title}</h5>}
                <p>{card.subtitle}</p>
                <div className='price'>
                    {card.old_price && <span className='old-price'> {Utils.numberWithSpaces(card.old_price)} {app.localizationsItems?.currency}</span>}
                    <span className='title'>{Utils.numberWithSpaces(card.price)} {app.localizationsItems?.currency}</span>
                </div>
            </div>
        </div>
        {(card.additional_fields?.price_configs || card.fields) &&
            <div className='additional'>
                <p>{app.localizationsItems?.additional}:
                    {card.additional_fields.price_configs.map((el, index) =>
                        <span key={index}> {el}, </span>
                    )}
                </p>
                {card.fields?.map((el, index) =>
                    <p key={index}>{el.name}:
                        <span>{el.value};</span>
                    </p>
                )}
            </div>
        }
    </div>
  )
})

export default OrderDetailCard