import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../..'
import DeliverySelectModal from '../modals/DeliverySelectModal';
import SelectCityModal from '../modals/SelectCityModal';
import AppServices from '../../services/general/appServices';

const DeliveryItemSet = observer(() => {
    const {user, app} = useContext(Context);

    const [show, setShow] = useState();
    const [showCity, setShowCity] = useState();

    const fetchCities = async () => {
        const res = await AppServices.locations();

        if(res.statusCode === 200){
            const newArr = res.content.items.map(it =>{
                let temp = {
                    value: it.id,
                    label: it.title,
                    location: it.value
                }
                return temp
            })
            app.setAllCity(newArr)
        }
    }

    useEffect(() => {
        fetchCities();
    }, [])

    useEffect(() => {
        if(localStorage.getItem('city') && localStorage.getItem('city') != 'undefined'){
            let temp = JSON.parse(localStorage.getItem('city'));
            if((app.allCities.find(it => it.value == temp.value && it.label == temp.label))){
                app.setCity(JSON.parse(localStorage.getItem('city')));
            }else{
                app.allCities?.length >= 1 && app.setCity(app.allCities[0]);
                setShowCity(true);
            }
        }else{
            app.allCities?.length >= 1 && app.setCity(app.allCities[0]);
            setShowCity(true)
        }
    }, [app.allCities])

  return (
    <>
        <div className='top-bar-address'>
            <div className='delivery-top'>{app.localizationsItems?.delivery_by} <b onClick={() => setShowCity(true)}>{JSON.parse(localStorage.getItem('city'))?.label}</b></div>
            <div className='location' onClick={() =>setShow(true)}>
                <i className='icon-map-pin'></i>
                <p>
                    {user?.address?.full_address ? 
                        user?.address?.full_address 
                        : 
                        app.localizationsItems?.delivery_select
                    }
                </p>
            </div>
        </div>
        {show && <DeliverySelectModal show={show} setShow={setShow} />}
        {showCity && <SelectCityModal show={showCity} setShow={setShowCity} />}
    </>
  )
})

export default DeliveryItemSet