import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import { Context } from '../..'
import { NavLink } from 'react-router-dom';
import Utils from '../../services/utils';
import BonusModal from '../modals/BonusModal';

const PorfileBonus = observer(({isModal, setShow}) => {
    const {app, user} = useContext(Context);
    const [bonusShow, setBonusShow] = useState();

  return (
    <div className='profile-bonus'>
        <div className='flex profile-bonus-top'>
            <div>{app.localizationsItems?.bonuses}</div>
            <div className='profile-info' onClick={() => setBonusShow(true)}>
                <i className='icon-info'></i>
            </div>
        </div>
        <div className='flex profile-bonus-middle'>
            <div className='bonus'>
                <i className='icon-coin'></i>
                {Utils.numberWithSpaces(user?.user?.balance)}
            </div>
        </div>
        <div className='flex profile-bonus-bottom'>
            {isModal ?
                <NavLink to={`/${app.lang}/user/bonuses`} onClick={() => setShow(false)}>
                    {app.localizationsItems?.history}
                </NavLink>
                :
                <NavLink to={`/${app.lang}/user/bonuses`}>
                    {app.localizationsItems?.history}
                </NavLink>
            }
            {/*<div className='profile-qcode'>
                <i className='icon-qr-code'></i>
                QR-код
  </div>*/}
        </div>
        {bonusShow && <BonusModal show={bonusShow} setShow={setBonusShow}/>}
    </div>
  )
})

export default PorfileBonus