import React, { useContext, useState } from 'react'
import { Context } from '../..';
import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import useFetchItems from '../../hooks/useFetchItems';
import UserServices from '../../services/general/userServices';
import { toast } from 'react-toastify';

const DeliveryUserCards = observer(({address, addressValue, CustomSubmit, setAddressValue}) => {
    const {app} = useContext(Context);
    const [activePoint, setActivePoint] = useState();

    const [activeAddAddress, setActiveAddress] = useState();
    
    const [loading, setLoading] = useState();

    const {register, getValues} = useFormContext();

    const {
        items,
        fetchItems
    } = useFetchItems(UserServices.addresses.index)

    const createAddress = async () => {
        const newData = {
            json_address: {
                entrance: getValues('entrance') ? getValues('entrance') : null,
                floor: getValues('floor') ? getValues('floor') : null,
                apartment: getValues('apartment') ? getValues('apartment') : null,
                code: getValues('code') ? getValues('code') : null,
                address: addressValue,
                lat: address?.lat,
                lng: address?.lng
            },
            full_address: addressValue
        }

        const res = await UserServices.addresses.store(newData);

        if (res.statusCode === 200) {
            fetchItems();
            toast.success(app.localizationsItems?.success_add_address);
            setActiveAddress(false);
        }else{
            toast.error(res.message)
        }
    }

    const deleteAddress = async (e, id) => {
        e.stopPropagation();
        setLoading(true);

        const res = await UserServices.addresses.delete(id);

        if (res.statusCode === 200) {
            fetchItems();
        }else{
            toast.error(res.message)
        }

        setLoading(false);
    }

    const handleChange = (e) => {
        setAddressValue(e.target.value)
    }

  return (
    <div className='delivery-wrapper'>
        <h3 className='mob-none'>{app.localizationsItems?.select_delivery_point}</h3>
        <div className='delivery-card-list'>
            {items?.items?.map((card, index) =>
                <div className={activePoint == card.id ? 'delivery-card-list-item active' : 'delivery-card-list-item'} key={index}>
                    {card.full_address}, 
                    <i className='icon-trash' onClick={e => deleteAddress(e, card.id)}></i>
                    <input className="delivery-card-input" type="radio" value={JSON.stringify({ pickup_id: card.id, full_address: card.full_address, json_address: card.json_address})} 
                    {...register('address_id', {required: true})} required onClick={() => setActivePoint(card.id)}/>
                </div>
            )}
            {loading &&
                <div className='loader-wrapper-absolute'> <span className="loader"></span> </div>
            }
        </div>
        <div className='main-btn btn-outline' onClick={() => setActiveAddress(true)}>{app.localizationsItems?.attach_address}</div>
        <div onClick={CustomSubmit} className='main-btn mr-0'>{app.localizationsItems?.save}</div>

        {activeAddAddress && <div className='delivery-wrapper-content'>
            <h3>{app.localizationsItems?.new_address}</h3>
            <input value={addressValue} onChange={e => handleChange(e)} placeholder={app.localizationsItems?.address} className="input input-center"></input>
            <div className="inputs-grid">
                <input type="text" className="input" {...register('entrance')} placeholder={app.localizationsItems?.entrance}/>
                <input type="text" className="input" {...register('floor')} placeholder={app.localizationsItems?.floor}/>
                <input type="text" className="input" {...register('apartment')} placeholder={app.localizationsItems?.apartment}/>
                <input type="text" className="input" {...register('code')} placeholder={app.localizationsItems?.code_domofon}/>
            </div>
            <div className='main-btn btn-gray' onClick={() => setActiveAddress(false)}>{app.localizationsItems?.cancel}</div>
            <div onClick={createAddress} className='main-btn'>{app.localizationsItems?.save}</div>
        </div>}
    </div>
  )
})

export default DeliveryUserCards