import React, { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const DeliveUserItems = observer(({address, CustomSubmit, addressValue, setAddressValue}) => {
    const {app} = useContext(Context);

    const {register} = useFormContext();

    const handleChange = (e) => {
        setAddressValue(e.target.value)
    }

  return (
    <>
        <h3 className='mob-none'>{app.localizationsItems?.new_address}</h3>
        <input value={addressValue} onChange={e => handleChange(e)} placeholder={app.localizationsItems?.address} className="input input-center"></input>
        <div className="inputs-grid">
            <input type="text" className="input" {...register('entrance')} placeholder={app.localizationsItems?.entrance}/>
            <input type="text" className="input" {...register('floor')} placeholder={app.localizationsItems?.floor}/>
            <input type="text" className="input" {...register('apartment')} placeholder={app.localizationsItems?.apartment}/>
            <input type="text" className="input" {...register('code')} placeholder={app.localizationsItems?.code_domofon}/>
        </div>
        <div onClick={CustomSubmit} className='main-btn'>{app.localizationsItems?.save}</div> 
    </>
  )
})

export default DeliveUserItems