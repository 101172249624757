import React from 'react'
import Slider from "react-slick";
import img from "../../images/placeholder.png"
import useFetchItems from '../../hooks/useFetchItems';
import AppServices from '../../services/general/appServices';

const MainSlider = () => {
    const {
        items,
        loading,
    } = useFetchItems(AppServices.stories)

    const settings = {
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode:true,
        variableWidth: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    centerMode:false,
                    variableWidth: false,
                }
            },
        ]
    }
    //console.log(items)
  return (
    items &&
    <div>
        <Slider {...settings} className='main-slider'>
            {items.items?.map((card, index) =>
                <div key={index} className='main-slider-item'>
                    <img src={card.detail_image ? card.detail_image : img} alt="" height={400} width={700}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; 
                            currentTarget.src = img
                        }}
                    />
                </div>
            )}
        </Slider>
    </div>
  )
}

export default MainSlider