import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Context } from '../..';

const PolitikaKonfidentsialnosti = observer(() => {
    const navigate = useNavigate();
    const {app} = useContext(Context);
    const{lang: lang2} = useParams();
    const [items, setItems] = useState();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2){
                navigate(`/${app.lang}/politika-konfidentsialnosti`);
            }
        }
    }, [app.lang])

    useEffect(() => {
        if(app.settings){
            let temp = app.settings.find(it => it.type === "privacy_policy")?.value;
            setItems(temp)
        }
    }, [app.settings])
    
  return (
    <div className='sample-page'>
        {
            items &&
            <div className='container'>
                <h2 className="mb-30">{items.title}</h2>
                <div dangerouslySetInnerHTML={{__html: items}}></div>
            </div>
        }
    </div>
  )
})

export default PolitikaKonfidentsialnosti