import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import AppRouter from './navigation/AppRouter'
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './App.scss';
import Header from './components/complex/Header';
import { Context } from '.';
import AppServices from './services/general/appServices';
import UserServices from './services/general/userServices';
import './i18n/i18n'
import { observer } from 'mobx-react-lite';
import Footer from './components/complex/Footer';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ScrollToTop from './hooks/scrollToTop';
import PageLoading from './components/simple/PageLoading';

const App = observer(() => {
    const {app, user} = useContext(Context);
    const [loading, setLoading] = useState();

    const checkAuth = async () => {
        if(user.token){
            const res = await UserServices.profile.index();
            if(res.statusCode === 200){
                
            }else{
                user.setUser({})
                user.removeToken();
                user.setTokenChange(Math.random().toString(16));
                user.setBasketChange(Math.random().toString(16));
            }
        }
    }

    const fetchdata = async () => {
        setLoading(true);
        const res = await AppServices.settings();

        if(res.statusCode === 200){
            let settings = res.content;

            app.setSettings(settings);

            let mainColor = settings.find(it => it.type === "color-primary")?.value;
            let bonusEnabled = settings.find(it => it.type === "bonuses_enabled")?.value;

            app.setBonusEnabled(bonusEnabled)
            mainColor && document.documentElement.style.setProperty('--mainColor', mainColor);
        }

        const res2 = await AppServices.localizations();

        if(res2.statusCode === 200){
            let temp;
            if(res2.content.find(it => it.slug === localStorage.getItem('i18nextLng'))){
                temp = localStorage.getItem('i18nextLng');
            }else{
                temp = res2.content[0].slug
                localStorage.setItem('i18nextLng', temp)
            }
            app.setLang(temp);

            let localizations = res2.content.find(it => it.slug === temp)?.description;

            let map2 = app.localizationsItems;
            for (let key in localizations) {
                map2[key] = localizations[key];
            }
            app.setLocalizationsItems(map2)

            app.setLanguages(res2.content)
        }

        const res3 = await AppServices.promoCodes();

        if(res3.statusCode === 200){
            app.setPromocodes(res3.content?.items)
        }

        setLoading(false);
    }

    useEffect(() => {
        fetchdata();
        checkAuth();
    }, [app.lang])

  return (
    <>  
        {
            loading ?
                <PageLoading/>
            :
                <BrowserRouter>
                    <ScrollToTop />
                    <Header/>
                    <AppRouter/>
                    <Footer/>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </BrowserRouter>
        }
    </>
  )
})

export default App