import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react'
import { useFormContext } from 'react-hook-form';
import { Context } from '../..';
import { toast } from 'react-toastify';
import UserServices from '../../services/general/userServices';
import EditAddressModal from '../modals/EditAddressModal';
import DeleteAddressModal from '../modals/DeleteAddressModal';

const ProfileAddressCard = observer(({card, fetchItems}) => {
    const {app} = useContext(Context);

    const [show, setShow] = useState();

    const [loading, setLoading] = useState();

    const [deleteModal, setDeleteModal] = useState();

    const deleteAddress = async (e) => {
        e.stopPropagation();
        setDeleteModal(true)
    }

    const editAddress = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setShow(true);
    }

  return (
    <label className='address-card'>
        {loading &&
            <div className='loader-wrapper-absolute'> <span className="loader"></span> </div>
        }
        <div className='card-item' onClick={(e) => editAddress(e)}>
            <span className='card-item-desk'>{app.localizationsItems?.street}: <b>{card.full_address}</b></span>
            <span className='card-item-mob'>{card.full_address} {card.json_address.apartment ? `,${app.localizationsItems?.apartment_short} ${card.json_address.apartment}` : ''}</span>
            <div className='card-item-buttons'>
                <i className='icon-pen'></i>
                <i className='icon-trash' onClick={e => deleteAddress(e)}></i>
            </div>
        </div>
        {card.json_address && <div className='card-grid card-item' onClick={(e) => editAddress(e)}>
            <span>{app.localizationsItems?.apartment}: <b>{card.json_address.apartment}</b></span>
            <span>{app.localizationsItems?.entrance}: <b>{card.json_address.entrance}</b></span>
            <span>{app.localizationsItems?.floor}: <b>{card.json_address.floor}</b></span>
            <span>{app.localizationsItems?.code_domofon}: <b>{card.json_address.code}</b></span>
        </div>}
        <div className='card-item mob-none' onClick={e => deleteAddress(e)}>
            <span></span>
            <i className='icon-trash'></i>
        </div>
        {show && <EditAddressModal show={show} setShow={setShow} fetchItems={fetchItems} id={card.id}/>}
        {deleteModal && <DeleteAddressModal show={deleteModal} setShow={setDeleteModal} id={card.id} fetchItems={fetchItems}/>}
    </label>
  )
})

export default ProfileAddressCard