import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../..'
import Utils from '../../services/utils';
import UserServices from '../../services/general/userServices';
import { toast } from 'react-toastify';

const ProductToCartButton = observer(({info, setCartOpen, setShow}) => {
    const {app, user} = useContext(Context);
    const [inCart, setInCart] = useState();
    const [count, setCount] = useState(1);

    useEffect(() => {
        if(user.cartProducts){
            const product = user.cartProducts.find(it => it.id === info.id);

            if(product && !(product.fields?.length >= 1)){
                setInCart(true);
                setCount(product.count)
            }
        }
    }, [user.cartProducts])

    const plusCount = async () => {
        setCount(count+1);
        const data = {
            uid: user.uid,
            product_id: info.id,
            count: count+1
        }

        const res = await UserServices.cart.update(data);

        if(res.statusCode === 200){
            user.setBasketChange(Math.random().toString(16))
        }else{
            setCount(count)
        }
    }

    const minusCount = async () => {
        if(count > 1){
            setCount(count-1);
            const data = {
                uid: user.uid,
                product_id: info.id,
                count: count-1
            }
    
            const res = await UserServices.cart.update(data);
    
            if(res.statusCode === 200){
                user.setBasketChange(Math.random().toString(16))
            }else{
                setCount(count)
            }
        }else if(count <= 1){
            deleteItem();
        }
    }

    const deleteItem = async () => {
        //setLoading(true);

        const data = {
            uid: user.uid,
            product_id: info.id
        }

        const res = await UserServices.cart.remove(data);

        if(res.statusCode === 200){
            user.setBasketChange(Math.random().toString(16));
            //setLoading(false);
            toast.success(res.message)
        }else{
            toast.error(res.message)
        }
    }

    const hanldeToCart = () => {
        setCartOpen(true);
        setShow(false);
    }

  return (
    <div className='flex product-counter'>
        {inCart ?
            <>
                <div className='input-count input-count-full'>
                    <div className='input-btn' onClick={minusCount}>
                        <i className='icon-minus'></i>
                    </div>
                    <div className='value'>{count} х {Utils.numberWithSpaces(info.price)} {app.localizationsItems?.currency}</div>
                    <div className='input-btn' onClick={plusCount}>
                        <i className='icon-plus'></i>
                    </div>
                </div>
                <div className='product-counter-button' onClick={hanldeToCart}>
                    <i className='icon-cart'></i>
                </div>
            </>
            :
            <button className='main-btn left blink-button'> 
                {Utils.numberWithSpaces(info.price)} {app.localizationsItems?.currency} 
                <i className='icon-plus'></i>
                <span className='flare'></span>
            </button>
        }
    </div>
  )
})

export default ProductToCartButton