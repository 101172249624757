import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import Utils from '../../services/utils';

const DeliveryModal = observer(({show, setShow, remainder, isFull, progress, deliveryPromocode}) => {
    const {app, user} = useContext(Context);
    const handleClose = () => setShow(false);

    const [deliveryPrice, setDeliveryPrice] = useState();

    useEffect(() => {
        let values = app.settings.find(it => it.type === "delivery")?.value;

        if(values?.length >= 1){
            setDeliveryPrice(values[0].delivery_price)
            /*for(let i = 0; i<values.length; i++){
                if(user.totalSum > values[i].price_from && (user.totalSum < values[i].price_to || (values[i].price_to === 0 || !values[i].price_to))){
                    setDeliveryPrice(values[i].delivery_price)
                }
            }*/
        }

    }, [])
    
  return (
    <Modal show={show} onHide={handleClose} centered className='delivery-stock-modal'>
        <Modal.Body className={isFull ? 'delivery-stock-content full' : 'delivery-stock-content'}>
            <i className='icon-close' onClick={handleClose}></i>
            <div className='delivery-stock-top'>
                <div className='delivery-stock-title'>
                    <div className='output' style={{ clipPath: `polygon(0% 0%, ${progress}% 0, ${progress}% 100%, 0% 100%)` }}></div>
                    <div className='output-inner'></div>
                    <div className='inner'><i className='icon-delivery'></i></div>
                </div>
                {isFull ?
                    <div>
                        <div className='delivery-stock-top-title'>{app.localizationsItems?.delivery_title}</div>
                        <p>{app.localizationsItems?.delivery_free}</p>
                    </div>
                    :
                    <div>
                        <div className='delivery-stock-top-title'>{app.localizationsItems?.delivery_more} {Utils.numberWithSpaces(remainder)} {app.localizationsItems?.currency}</div>
                        <p>{app.localizationsItems?.to_free_delivery}</p>
                    </div>
                }
            </div>
            <div>
                <div className='delivery-stock-item'>{app.localizationsItems?.delivery_from} {Utils.numberWithSpaces(deliveryPromocode?.min_price)} {app.localizationsItems?.currency} <b>{Utils.numberWithSpaces(deliveryPrice)} {app.localizationsItems?.currency}</b></div>
                <div className='delivery-stock-item'><b>{app.localizationsItems?.delivery_to_free} {Utils.numberWithSpaces(deliveryPromocode?.min_price)} {app.localizationsItems?.currency}</b> <b>0 {app.localizationsItems?.currency}</b></div>
                {/*<div className='delivery-stock-item'>{app.localizationsItems?.delivery_time} <b>{app.localizationsItems?.delivery_time_value}</b></div>*/}
            </div>
            <button className='main-btn' onClick={handleClose}>{app.localizationsItems?.sure}</button>
        </Modal.Body>
    </Modal>
  )
})

export default DeliveryModal