import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import LangCitySelectModal from '../modals/LangCitySelectModal';

const LangCitySelect = observer(() => {
    const {app} = useContext(Context);

    const [show, setShow] = useState();
    

  return (
    <>
        <div className='lang-city-select' onClick={() => setShow(true)}>
            <p>Астана</p>
            <i className='icon-globe' data-title={app.lang}></i>
        </div>
        {show && <LangCitySelectModal show={show} setShow={setShow} />}
    </>
  )
})

export default LangCitySelect