import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import NotificationCard from '../simple/NotificationCard';
import NotificationServices from '../../services/general/notificationsServices';
import useFetchItems from '../../hooks/useFetchItems';
import NotificationLoading from '../simple/NotificationLoading';

const Notifications = observer(() => {
    const {app} = useContext(Context);

    const navigate = useNavigate();

    const {
        items,
        loading
    } = useFetchItems(NotificationServices.index)

  return (
    <div className='profile-dashboard'>
        <div className='button-back'>
            <i className='icon-chevron-left' onClick={() => navigate(`/${app.lang}/mob-user`)}></i>
            <h3>{app.localizationsItems?.notifications}</h3>
        </div>
        {loading ?
            <NotificationLoading/>
            :
            <div>
                {items?.items?.map((card, index) =>
                    <NotificationCard key={index} card={card}/>
                )}
            </div>
        }
    </div>
  )
})

export default Notifications