import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../..';
import img from "../../images/banner_mob.png";
import appStore from "../../images/appstore.png"
import playMarket from "../../images/googleplay.png"

const AppAdvertisingBanner = observer(() => {
    const {app} = useContext(Context);

    const [appStoreLink, setAppStoreLink] = useState();
    const [googlePlayLink, setGooglePlayLink] = useState();
    
    useEffect(() => {
        if(app.settings){
            setAppStoreLink(app.settings.find(it => it.type === "app-store-link")?.value);
            setGooglePlayLink(app.settings.find(it => it.type === "google-play-link")?.value);
        }
    }, [app.settings])

  return (
    (appStoreLink || googlePlayLink) &&
    <div className='container'>
        <div className='app-banner'>
            {/*<img src={img} alt="" className='mob-image'/>*/}
            <div className='app-baner-content'>
                <div className='subtitle'>ПОПРОБУЙТЕ НАШЕ ПРИЛОЖЕНИЕ</div>
                <h2>Всегда на ногах? Перейдите на приложение!</h2>
                <div className="description-flex">
                    {appStoreLink && <a href={appStoreLink} target="_blank" rel="noopener">
                        <img src={appStore} alt=""/>
                    </a>}
                    {googlePlayLink && <a href={googlePlayLink} target="_blank" rel="noopener">
                        <img src={playMarket} alt=""/>
                    </a>}
                </div>
            </div>
        </div>
    </div>
  )
})

export default AppAdvertisingBanner