import React, { useEffect, useState } from 'react'
import Slider from 'react-slick';
import img from "../../images/placeholder.png"
import StoriesLoading from '../simple/StoriesLoading';
import AppServices from '../../services/general/appServices';
import useFetchItems from '../../hooks/useFetchItems';
import StoriesModal from '../modals/StoriesModal';

const StoriesComponent = () => {
    const [show, setShow] = useState();
    const [currentStory, setCurrentStory] = useState(0);

    const [loading, setLoading] = useState(true);
    const [visited, setVisited] = useState([]);

    const {
        items,
    } = useFetchItems(AppServices.stories)
    
    useEffect(() => {
        if(items?.items){
            const localVisitedStoriesArr = localStorage.getItem("stories_opened") ? JSON.parse(localStorage.getItem("stories_opened")) : [];
            const actualVisitedStoriesArr = localVisitedStoriesArr.filter(item => items.items.find(it => it.id === item))

            if(localVisitedStoriesArr !== actualVisitedStoriesArr){
                localStorage.setItem('stories_opened', JSON.stringify(actualVisitedStoriesArr));
            }
            setVisited(actualVisitedStoriesArr);
            setLoading(false);
        }
    }, [items])

    const settings = {
        arrows: true,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 1250,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 996,
            settings: {
                slidesToShow: 5,
            }
        },
        {
            breakpoint: 886,
            settings: {
                slidesToShow: 4,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 3,
            }
        },
    ]
    }

    const handleOpen = (card, index) => {
        setCurrentStory(index)

        let names = localStorage.getItem("stories_opened") ? JSON.parse(localStorage.getItem("stories_opened")) : [];

        if(!names.find(it => it === card.id)){
            names.push(card.id);
        }
        setVisited(names)
        localStorage.setItem('stories_opened', JSON.stringify(names));

        setShow(true);
    }

  return (
    <>
        {loading ?
            <StoriesLoading/>
            :
            <div className='container'>
                <div className='stories'>
                    <Slider {...settings} className='stories-slider'>
                        {items.items?.map((card, index) =>
                            <div key={index} className={visited.find(it => it === card.id) ? 'stories-item visited' : 'stories-item'} onClick={() => handleOpen(card, index)}>
                                <img src={card.preview_image ? card.preview_image : img} alt="" 
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; 
                                        currentTarget.src = img
                                    }}
                                />
                            </div>
                        )}
                    </Slider>
                </div>

                {show &&
                    <StoriesModal
                        show={show}
                        setShow={setShow}
                        stories={items.items}
                        setVisited={setVisited}
                        currentStory={currentStory}
                    />
                }
            </div>
        }
    </>
  )
}

export default StoriesComponent